export const addCookiebot = (notice, id) => {

  if (notice == true && id) {

    let scripts = [
      {
        src: "https://consent.cookiebot.com/uc.js",
        id: "Cookiebot",
        'data-cbid': id,
        type: "text/javascript",
        'data-blockingmode': "auto"
      }
    ];

    useHead({
      script: scripts
    });
  }
};

export const addCookiebotDeclarationScript = (notice, id) => {

  var cookiebotDeclarationExists = document.getElementById('cookiebotDeclaration');
  if (notice && id && cookiebotDeclarationExists) {
    const script = document.createElement('script');
    script.id = "CookieDeclaration";
    script.src = "https://consent.cookiebot.com/" + id + "/cd.js";
    script.type = "text/javascript";
    script.async = true;
    document.getElementById('cookiebotDeclaration').appendChild(script);
  }
};